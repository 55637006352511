<div class="container">
  <div class="row">
    <div class="col">
      <div class="breadcrumbs">
        <ol class="breadcrumb">
          <li *ngFor="let breadcrumb of breadcrumbs">
            <a href="javascript:void(0)" routerLink="{{'/u' + breadcrumb.url}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              {{ breadcrumb.label }}
            </a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
