import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor() { }

  /**
   * @todo Implement if desired, currently just does console.log()
   * @summary Can be used to log errors or handle caught errors gracefully
   * @param error Any error type
   */
  handleError(error: any): void {
    console.error(error);
  }

  /**
   * @todo Implement if desired, currently just does console.log()
   * @summary called during failure of any HTTP request using the base Rest API service class.
   * @param error Http Error to log
   *
   * Can be used to log http errors and the requests that are related to them for bug tracking purposes.
   */
  logHttpError(error: HttpErrorResponse): void {
    console.error(error);
  }
}
