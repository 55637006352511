import { Injectable } from '@angular/core';
import { ILookupApiResponse } from '@sharedModels/api/lookup';
import { NotificationsModel } from '@userModels/notifications';
import { LookupType } from '@sharedLookups/lookup-keys';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserApiService } from './api/user-api.service';
import { LookupsService } from '@sharedServices/lookups.service';
import { NotificationService } from './notification.service';
import { INotificationSettings } from '../models/notification';
import { UserService } from './user.service';

@Injectable()
export class NotificationManagementService {
  filterOptions: ILookupApiResponse[] = [];
  notifcations = new NotificationsModel([]);
  notifications$ = new BehaviorSubject<NotificationsModel>(this.notifcations);

  constructor(
    private _userApi: UserApiService,
    private _lookupService: LookupsService,
    private _userService: UserService
    // private _notificationService: NotificationService
  ) { }

  public async refreshNotifications(): Promise<NotificationsModel> {
    const notifications = await this._userApi.getNotifications();

    if (notifications.HasError || !notifications.data) {
      notifications.data = [];
    }

    if (!this.filterOptions.length) {
      this.filterOptions = await this._lookupService.getLookup(LookupType.StudentEventTypes, {
        query: '?notificationOnly=true', lookup: LookupType.StudentEventTypes
      } );
    }

    this.notifcations = new NotificationsModel(notifications.data);

    this.notifications$.next(this.notifcations);

    return this.notifcations;
  }

  public getNotifications$(): Observable<NotificationsModel> {
    return this.notifications$.asObservable();
  }

  public async getNotificationSettings(): Promise<INotificationSettings> {
    const user = await this._userService.getCurrentUser();

    const response = await this._userApi.getUser(user.id);
    const { generalNotification, emailNotification } = response.data;

    return { generalNotification, emailNotification };
  }

  public async saveNotificationSettings(settings: INotificationSettings) {
    const user = await this._userService.getCurrentUser();
    const response = await this._userApi.getUser(user.id);

    //add settings to response.data object using spread or destructuring here...
    const updatedUserData = {
      ...response.data,
      ...settings
    };

    this._userApi.updateUserNotificationSettings(user.id, updatedUserData);
  }

  public async markNotificationRead(notificationId: number): Promise<void> {
    const result = await this._userApi.markNotificationAsRead(notificationId);
    if (result.HasError) {
      // return this._notificationService.pushSimpleNotification('There was an error marking the notification as read', false);
      return;
    }

    this.notifcations.readNotification(notificationId);
    this.notifications$.next(this.notifcations);
  }

  public async updateNotifications(action: string, notificationIds: number[]): Promise<void> {
    const result = await this._userApi.updateNotifications(action, notificationIds);
    if (result.HasError) {
      // return this._notificationService.pushSimpleNotification('There was an error marking the notification as read', false);
      return;
    }
    this.notifcations.updateNotifications(action, notificationIds);
    this.notifications$.next(this.notifcations);
  }

  public async deleteNotification(notificationId: number): Promise<void> {
    const result = await this._userApi.deleteNotification(notificationId);
    if (result.HasError) {
      // return this._notificationService.pushSimpleNotification('There was an error deleting the notification', false);
      return;
    }

    this.notifcations.deleteNotification(notificationId);
    this.notifications$.next(this.notifcations);
  }
}
