<div class="mb-2">
  <mat-form-field appearance="outline" class="w-100 {{errorClass}}" floatLabel="always" [ngClass]="{'no-hint': !hint}">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="control" (selectionChange)="onSelectionChange($event)" panelClass="max-height-select" [required]="required" *ngIf="!multiple" placeholder="Select One..." (closed)="onPanelClose()">
      <mat-form-field class="search-input pt-2" floatLabel="always" *ngIf="search">
        <input matInput
          [formControl]="filterControl"
          type="text"
          name="filter-options"
          id="filter-options"
          placeholder="Search"
          autcomplete="off"
          (keydown)="$event.stopPropagation()"
          (keyup)="filterOptions($event.target.value)">
      </mat-form-field>
      <mat-option *ngFor="let option of options" [ngClass]="{'hide': option.isHidden}" [value]="!valueKey ? option : option[valueKey]">
        {{ !optionKey ? option : option[optionKey] }}
      </mat-option>
    </mat-select>

    <mat-select [formControl]="control" panelClass="max-height-select" [required]="required" multiple *ngIf="multiple" role="listbox" placeholder="Select Multiple..." (closed)="onPanelClose()">
      <mat-form-field class="search-input pt-2" floatLabel="always" *ngIf="search">
        <input matInput
          [formControl]="filterControl"
          type="text"
          name="filter-options"
          id="filter-options"
          placeholder="Search"
          autcomplete="off"
          (keydown)="$event.stopPropagation()"
          (keyup)="filterOptions($event.target.value)">
      </mat-form-field>

      <div class="select-all" [ngClass]="{'hide': hideSelectAll}">
        <mat-checkbox color="primary" (change)="toggleSelectAll()" [value]="true" [formControl]="selectAllControl">Select All</mat-checkbox>
      </div>
      <mat-option *ngFor="let option of options" [ngClass]="{'hide': option.isHidden}" [value]="!valueKey ? option : option[valueKey]" (click)="checkValues()">
        {{ !optionKey ? option : option[optionKey] }}
      </mat-option>
    </mat-select>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  </mat-form-field>


  <ng-container *ngIf="multiple && showSelectedOptions">
    <div class="row mb-4">
      <div class="col-12 col-md-6 col-lg-4" *ngFor="let option of selectedOptions">
        <mat-checkbox color="primary" [checked]="true" (change)="disabledSelectedOption($event, option)">{{ !optionKey ? option : option[optionKey] }}</mat-checkbox>
      </div>
    </div>
  </ng-container>
</div>
