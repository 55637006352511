import { INotification } from '@userModels/notification';

export class NotificationsModel {
  notifications: INotification[];
  numRead: number;
  numUnread: number;

  constructor(notifications: INotification[]) {
    this.notifications = notifications;
    this.setReadCounts();
  }

  readNotification(notificationId: number): void {
    const index = this.notifications.findIndex(n => n.id === notificationId);

    if (index > -1) {
      this.notifications[index].markedAsRead = true;
    }

    this.setReadCounts();
  }

  unReadNotification(notificationId: number): void {
    const index = this.notifications.findIndex(n => n.id === notificationId);

    if (index > -1) {
      this.notifications[index].markedAsRead = false;
    }

    this.setReadCounts();
  }

  updateNotifications(action: string, notificationIds: number[]): void {
    notificationIds.forEach(x => {
      if (action == 'read') {
        this.readNotification(x);
      } else if (action == 'unread') {
        this.unReadNotification(x);
      } else if (action == 'delete') {
        this.deleteNotification(x);
      }
    })
  }

  deleteNotification(notificationId: number): void {
    const index = this.notifications.findIndex(n => n.id === notificationId);

    if (index > -1) {
      this.notifications.splice(index, 1);
    }

    this.setReadCounts();
  }

  private setReadCounts() {
    this.numRead = this.notifications.filter(n => n.markedAsRead).length;
    this.numUnread = this.notifications.filter(n => !n.markedAsRead).length;
  }
}
