<div class="mb-2">
  <div class="row">
    <div class="col-12 mb-1">
      <mat-label>{{label}}</mat-label>
    </div>
    <div class="col-5">
      <mat-radio-group aria-labelledby="example-radio-group-label" [formControl]="control" class="example-radio-group">
        <mat-radio-button *ngFor="let option of options" class="example-radio-button"
          color="primary"
          [value]="option[valueKey]">
          {{option[optionKey]}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
