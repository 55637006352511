<mat-toolbar class="navbar" color="primary">
  <a href="javascript:void(0)" routerLink="/u/dashboard">
    <img class="logo" src="/assets/logo/sst-logo.png" alt="Beyond SST Logo">
  </a>



  <ng-container *ngIf="isHelpDeskSession">
    <button (click)="returnToHelpDesk()" class="btn btn-danger">Return To Help Desk</button>

  </ng-container>

  <span class="create-space-between"></span>

  <div class="d-block d-md-none" *ngIf="isLoggedIn">
    <button mat-icon-button (click)="viewResponsiveMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div *ngIf="isLoggedIn" class="d-none d-md-block">
    <button mat-button [matMenuTriggerFor]="adminMenu" *ngIf="!isTeacher">Administration</button>
    <mat-menu #adminMenu="matMenu">
      <button mat-menu-item routerLink="/u/dashboard/news" *ngIf="isDistrict || isSchool || isSelpa">Manage Dashboard News</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/help-desk" *ngIf="isAdmin">Help Desk</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/news" *ngIf="isAdmin">Manage Landing News</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/user-guide-list" *ngIf="isAdmin">Manage User Guide</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/system-notification" *ngIf="isAdmin">Manage System Notification</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/districts" *ngIf="isAdmin">Manage Districts</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/schools" *ngIf="isAdmin">Manage Schools</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/integrations" *ngIf="isAdmin">Manage Integrations</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/admin/setup-password" *ngIf="isAdmin">Setup Password Emails</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="reportMenu" *ngIf="!isAdmin">Reports</button>
    <mat-menu #reportMenu="matMenu">
      <button mat-menu-item routerLink="/u/reports/end-of-year" *ngIf="isDistrict || isSchool || isSelpa">User Engagement</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/duplicate-student">Duplicate Students</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/student-event">Student Event</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/toms" *ngIf="isDistrict || isSchool">Toms</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/overdue-forms">Overdue Forms</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/forms-status" *ngIf="isDistrict || isSchool || isSelpa">Forms Status</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/overdue-students">Overdue Students</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/reports/unassigned-students" *ngIf="!isTeacher">Unassigned Students</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="libraryMenu" *ngIf="!isAdmin">Library</button>
    <mat-menu #libraryMenu="matMenu">
      <button mat-menu-item routerLink="/u/library">Document Library</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="searchMenu" *ngIf="!isAdmin">Searches</button>
    <mat-menu #searchMenu="matMenu">
      <button mat-menu-item routerLink="/u/searches/student">Search For Students</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/searches/form">Search By Forms</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="studentMenu">Students</button>
    <mat-menu #studentMenu="matMenu">
      <button mat-menu-item routerLink="/u/students">Student Listing</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/students/add" *ngIf="addStudents && !isAdmin && !usesApi">Add Student</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/students/staging" *ngIf="addStudents && !isAdmin && usesApi">Add Student</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/students/request" *ngIf="!addStudents && isTeacher && !usesApi">Add Student Request</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/students/staging" *ngIf="!addStudents && isTeacher && usesApi">Add Student Request</button>
      <mat-divider></mat-divider>
      <button mat-menu-item [routerLink]="['/u/students/transfers']" [queryParams]="{ filter: 'all'}" *ngIf="addStudents && !isAdmin">Transfer Requests</button>
      <mat-divider></mat-divider>
      <button mat-menu-item [routerLink]="['/u/students/referrals']" [queryParams]="{ filter: 0}" *ngIf="referStudents && !isAdmin && !isTeacher">Referrals</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/students/pending" *ngIf="!isAdmin">Pending Students</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/dashboard/signatures" [queryParams]="{ onlyActive: false}" *ngIf="isDistrict || isSchool || isSelpa">Electronic Signatures</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/u/students/eoy" *ngIf="isDistrict || isSchool || isSelpa">End Of Year Process</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="helpMenu">Help Center</button>
    <mat-menu #helpMenu="matMenu">
      <button mat-menu-item routerLink="/u/dashboard/manuals">User Guide</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="userMenu" *ngIf="manageUsers && !isTeacher">Users</button>
    <mat-menu #userMenu="matMenu">
      <ng-container>
        <button mat-menu-item routerLink="/u/users">Users</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/u/users/permission">User Permissions</button>
      </ng-container>
    </mat-menu>

  </div>

  <ng-container *ngIf="isLoggedIn">
    <button mat-icon-button [matMenuTriggerFor]="accountMenu" aria-label="Account Button">
      <mat-icon>person_outline</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu">
      <button mat-menu-item routerLink="/u/dashboard/account">My Account</button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>

    <button mat-icon-button routerLink="/u/dashboard/help-center" aria-label="Help Button">
      <mat-icon>help_outline</mat-icon>
    </button>
    <ng-container *ngIf="notifications$ | async as notifications">
      <button mat-icon-button (click)="viewNotifications()" [matBadgeHidden]="notifications.numUnread <= 0" matBadgeSize="small" [matBadge]="notifications.numUnread" matBadgeColor="warn" aria-label="Notification Button">
        <mat-icon>notifications_none</mat-icon>
        <!-- For Screen Readers -->
        <!-- <span class="cdk-visually-hidden">Notifications Icon</span> -->
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!isLoggedIn">
    <button mat-button routerLink="/contact">Contact Us</button>
    <button mat-button routerLink="/login">Login</button>
    <a href="https://www.facebook.com/pg/BeyondSST/about/" target="_blank" title="Beyond SST Facebook"><i class="fab fa-facebook fa-2x"></i></a>
    <a href="https://twitter.com/beyondsst?lang=en" target="_blank" title="Beyond SST Twitter"><i class="fab fa-twitter fa-2x"></i></a>
    <a href="https://www.instagram.com/CodeStackSJ/" target="_blank" title="Beyond SST Instagram"><i class="fab fa-instagram fa-2x"></i></a>
    <a href="https://www.youtube.com/playlist?list=PLiEZW_U8qc5wRHYq_T_Xc4dAUWpWjp_-L" target="_blank" title="Beyond SST YouTube"><i class="fab fa-youtube fa-2x"></i></a>
  </ng-container>
</mat-toolbar>

