import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * @summary Get a value form local storage based on the provided key
   * @param key The key to search local storage for
   * @param parse Defaults to true, set to false to not parse the value (e.g. expecting a string response)
   */
  getLocalStorage<T>(key: string, parse: boolean = true): T {
    const result = localStorage.getItem(key);

    return parse === true
      ? JSON.parse(result)
      : result;
  }

  /**
   * @summary Get a value form local storage based on the provided key. storage can expire
   * @param key The key to search local storage for
   * @param parse Defaults to true, set to false to not parse the value (e.g. expecting a string response)
   */
  getLocalStorageWithExpiry<T>(key: string, parse: boolean = true): T {
    const result = localStorage.getItem(key);

    if (!result) {
      return null;
    }

    const item = JSON.parse(result);

    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
      localStorage.removeItem(key);
      return null;
    }

    try {
      return parse === true
        ? JSON.parse(item.value)
        : item.value;
    } catch (e) {
      // Let's assume the error is that we already have parsed the payload
      // So just return that
      return item.value;
    }
    
  }

  /**
   * @summary Set a value in local storage based on the provided key and data
   * @param key The key to store in local storage by
   * @param data The data to persist
   * @param stringify Defaults to true, can be set to false if the provided data is already a string
   */
  setLocalStorage(key: string, data: any, stringify: boolean = true): void {
    let dataString = stringify
      ? JSON.stringify(data)
      : data as string;

    localStorage.setItem(key, dataString);
  }

  /**
 * @summary Set a value in local storage based on the provided key and data. Supports expiration time.
 * @param key The key to store in local storage by
 * @param data The data to persist
 * @param stringify Defaults to true, can be set to false if the provided data is already a string
 */
  setLocalStorageWithExpiry(key: string, data: any, ttl:number, stringify: boolean = true): void {
    const item = {
      value: data,                  // store the value within this object
      ttl: Date.now() + (ttl * 1000),   // store the TTL (time to live)
    }

    let dataString = stringify
      ? JSON.stringify(item)
      : data as string;

    localStorage.setItem(key, dataString);
  }


  /**
   * @summary Remove an item from local storage
   * @param key The key to remove from local storage
   */
  removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * @summary Get a value form session storage based on the provided key
   * @param key The key to search session storage for
   * @param parse Defaults to true, set to false to not parse the value (e.g. expecting a string response)
   */
  getSessionStorage<T>(key: string, parse: boolean = true): T {
    const result = sessionStorage.getItem(key);

    return parse === true
      ? JSON.parse(result)
      : result;
  }

  /**
   * @summary Set a value in session storage based on the provided key and data
   * @param key The key to store in session storage by
   * @param data The data to persist
   * @param stringify Defaults to true, can be set to false if the provided data is already a string
   */
  setSessionStorage(key: string, data: any, stringify: boolean = true): void {
    let dataString = stringify
      ? JSON.stringify(data)
      : data as string;

    sessionStorage.setItem(key, dataString);
  }

  /**
   * @summary Remove an item from session storage
   * @param key The key to remove from session storage
   */
  removeSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }
}
