import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'sst-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnChanges {
  @Input() label: string;
  @Input() valueKey: string;
  @Input() optionKey: string;
  @Input() options: any[] = [];
  @Input() required: boolean;
  @Input() control: UntypedFormGroup;

  ngOnChanges(): void {
    if (this.options) {
      const values = this.control.value as string[];

      this.options.map(option => {
        option.isChecked = values.find(value => option[this.valueKey] === value);
        return option;
      });
    }
  }

  onChange(event: MatCheckboxChange) {
    const value = event.source.value;
    const values = this.control.value as string[];

    if (event.checked) {
      values.push(value);
    } else {
      const i = values.findIndex(v => v === value);
      values.splice(i, 1);
    }

    this.control.patchValue(values);
  }
}
