import { Injectable } from '@angular/core';
import { StorageService } from '@sharedServices/storage.service';
import { ILoginResponse } from '@sharedModels/api/login';
import { IUser } from '@sharedModels/api/user';
import { UserPermissions } from '@sharedLookups/user-roles.lookup';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private _storage: StorageService
  ) { }

  /**
   * @summary Gets the currnent user from local storage
   * @returns IUser object
   */
  public getCurrentUser(): IUser {
    const jwt = this._storage.getLocalStorage('jwt', true) as ILoginResponse;
    if (!jwt) { /* handle failure */ } // probably logout and return to login

    const { user } = jwt;

    return user;
  }

  /**
   * @summary Checks if the current user has the provided permission
   * @param permissions The UserPermissions enum to check for
   * @return boolean as success
   */
  public hasPermission(permissions: UserPermissions): boolean {
    const user = this.getCurrentUser();

    return user.permissions.includes(permissions);
  }

  /**
   * @summary Checks if the current user has all of the provided permissions
   * @param permissions A list of UserPermissions enum to check for
   * @return boolean as success
   */
  public hasPermissions(permissions: UserPermissions[]): boolean {
    const user = this.getCurrentUser();
    const missingPermissions = permissions.filter(permission => !user.permissions.includes(permission));

    return missingPermissions.length === 0;
  }
}
