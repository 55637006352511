import { Injectable } from "@angular/core";
import { LookupApiService } from './api/lookup-api.service';
import { ILookupKey } from '@sharedModels/lookup-key';
import { LookupType, MasterLookupKeys } from '../lookups/lookup-keys';
import { ILookupApiResponse, ILookupOptions } from '@sharedModels/api/lookup';
import { ILookupQueryOption } from '../models/api/lookup';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class LookupsService {
  constructor(private _api: LookupApiService) { }

  /**
   * @summary Gets a list of different lookup values
   * @param lookupTypes a list of LookupType's to fetch data for
   * @param options List of lookup options that can be configured per LookupType
   * @returns Promise generic T list response of lookups
   */
  public async getLookups<T>(lookupTypes: LookupType[], options?: ILookupOptions): Promise<T> {
    const lang = options?.lang || 'en';
    let result = {} as T;

    // Loop through all provided lookup types to get details for each one
    await Promise.all(lookupTypes.map(async (type: LookupType) => {
      const lookup = this.findLookupKey(type);

      // Todo: Revist this, the way this string is being built up is dirty
      // begin building the query string
      let query = '';

      if (options?.queryOptions?.length) {
        // Get this lookup type from where we are in the above loop, find the matching options
        var option = options.queryOptions.find(option => option.lookup === type);

        // Set the query
        query = option?.query;
      }

      // If there is a query add lang, else apply lang query string
      query = query?.includes('?') ? `${query}&lang=${lang}` : `?lang=${lang}`;

      // Get the lookup
      const endpoint = `${lookup.endpoint}${query}`;
      const response = await this.getLookupData(endpoint);

      // Set the result in an option, the endpoint will be used as the key for the lookup
      // (e.g. LookupType.Permissions with endpoint "permissions") will set result.permissions = response
      result[lookup.endpoint] = response;
    }));

    return result;
  }

  /**
   * @summary Gets a single lookup value
   * @param lookupType The LookupType to get values for
   * @param option ILookupQueryOption such as a query string to get spanish
   * @returns Promise of an ILookupApiResponse list
   */
  public async getLookup(lookupType: LookupType, option?: ILookupQueryOption): Promise<ILookupApiResponse[]> {
    const lookup = this.findLookupKey(lookupType);

    const query = option ? option.query : '';
    const endpoint = `${lookup.endpoint}${query}`;

    return await this.getLookupData(endpoint);
  }

  /**
   * @summary Gets requested lookup data from the API
   * @param endpoint The endpoint to hit to get the lookup value
   * @returns Promise of an ILookupApiResponse list
   */
  private async getLookupData(endpoint: string): Promise<ILookupApiResponse[]> {
    const response = await this._api.getLookup(endpoint);
    if (response.HasError || !response.data.length) {
      return [];
    }

    return response.data || [];
  }

  /**
   *
   * @param lookupType The LookupType to get details for
   */
  private findLookupKey(lookupType: LookupType): ILookupKey {
    return MasterLookupKeys.find((key: ILookupKey) => key.lookup === lookupType);
  }
}
