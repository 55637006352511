import { Component, Input } from '@angular/core';

@Component({
  selector: 'sst-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent {
  @Input() margin: string = 'mb-4';

  constructor() { }
}
