import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { StorageService } from './storage.service';
import { ILoginResponse } from '@sharedModels/api/login';

const _jwt = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class JwtService {
  constructor(
    private _storage: StorageService
  ) { }

  /**
   * @summary Decodes the current users JWT
   */
  public decodeToken() {
    const token = this.getToken();
    return _jwt.decodeToken(token);
  }

  /**
  * @summary Returns users authorized role from JWT cred
  */
  public getAuthorizedRole() {
    let decoded = this.decodeToken();
    return decoded.role;
  }

  /**
   * @summary Get the expiration date of the current users JWT
   */
  public getTokenExpirationDate(): Date {
    const token = this.getToken();

    return _jwt.getTokenExpirationDate(token);
  }

  /**
   * @summary check if a JWT is expired
   * @param offsetSeconds optional offset to check expiration
   * @returns boolean value
   */
  public isTokenExpired(offsetSeconds?: number): boolean {
    const token = this.getToken();

    return _jwt.isTokenExpired(token, offsetSeconds);
  }

  /**
   * @summary gets the current JWT from local storage
   * @returns JWT string
   */
  public getToken(): string {
    const jwt = this._storage.getLocalStorage<ILoginResponse>('jwt');

    return jwt?.token;
  }

  /**
   * @summary checks if the current JWT is expired
   * @returns boolean as success
   */
  public isAuthorized(): boolean {
    return this.isTokenExpired() === false;
  }
}
