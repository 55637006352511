import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sst-well',
  templateUrl: './well.component.html',
  styleUrls: ['./well.component.scss']
})
export class WellComponent implements OnInit {
  bgcolor: string;
  @Input() color: string = 'light';

  constructor() { }

  ngOnInit(): void {
    this.bgcolor = `bg-${this.color}`;
  }

}
