import { Component, Optional } from '@angular/core';
import { SidenavService } from '@userServices/sidenav.service';
import { SidenavView } from '@userModels/sidenav-view';
import { StorageService } from '@sharedServices/storage.service';
import { Router } from '@angular/router';
import { JwtService } from '@sharedServices/jwt.service';
import { UserService } from '@userServices/user.service';
import { UserPermissions, UserRoles } from 'src/app/lookups/user-roles.lookup';
import { Observable } from 'rxjs';
import { NotificationsModel } from '@userModels/notifications';
import { NotificationManagementService } from '@userServices/notification-management.service';
import { IUser } from '../../models/api/user';
import { AdminApiService } from '../../views/user/services/api/admin-api.service';
import { ILoginResponse } from '../../models/api/login';
import { IMenuSidenavCreds } from '../../views/user/components/sidenavs/menu-sidenav/menu-sidenav.model';
import { AuthApiService } from '../../services/api/auth-api.service';

@Component({
  selector: 'sst-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  notifications$: Observable<NotificationsModel>
  isLoggedIn: boolean;
  manageUsers: boolean;
  manageAnnouncements: boolean;
  addStudents: boolean;
  referStudents: boolean = false;
  user: IUser;
  isAdmin: boolean;
  isSelpa: boolean;
  isDistrict: boolean;
  isSchool: boolean;
  isTeacher: boolean;
  isHelpDeskSession: boolean;
  usesApi: boolean = false;

  constructor(
    private _sidenav: SidenavService,
    private _storage: StorageService,
    private _adminApi: AdminApiService,
    private _authApi: AuthApiService,
    private _router: Router,
    private _jwt: JwtService,
    private _user: UserService,
    @Optional() private _notificationManagementService: NotificationManagementService
  ) { }

  async ngOnInit() {

    this.isLoggedIn = this._jwt.isAuthorized();

    if (this.isLoggedIn) {
      let decodedRole = this._jwt.decodeToken().role;

      if (decodedRole == UserRoles.Admin) {
        //check for active hd session
        let result = await this._adminApi.checkActiveHelpDeskSession();
        if (result.HasError) {
          //return;
        } else {
          if (result.data > 0) {
            this.isHelpDeskSession = true;
          }
        }
      }
    }


    if (this.isLoggedIn) {
      this.manageUsers = this._user.hasPermission(UserPermissions.ManageUsers);
      this.manageAnnouncements = this._user.hasPermission(UserPermissions.Announcements);
      this.addStudents = this._user.hasPermission(UserPermissions.AddStudent);
      this.referStudents = this._user.hasPermission(UserPermissions.ReferStudents);
      this.notifications$ = this._notificationManagementService.getNotifications$();
      this.user = this._user.getCurrentUser();
      this.usesApi = this.user.usesApi;
      if (this.user.roleId == UserRoles.Admin) {
        this.isAdmin = true;
      } else if (this.user.roleId == UserRoles.Selpa) {
        this.isSelpa = true;
      } else if (this.user.roleId == UserRoles.District) {
        this.isDistrict = true;
      } else if (this.user.roleId == UserRoles.School) {
        this.isSchool = true;
      } else if (this.user.roleId == UserRoles.Teacher) {
        this.isTeacher = true;
      }
    }
  }

  viewNotifications() {
    this._sidenav.openSidenav(SidenavView.notifications);
  }

  viewResponsiveMenu() {
    const creds = {} as IMenuSidenavCreds;
    creds.isAdmin = this.isAdmin;
    creds.isDistrict = this.isDistrict;
    creds.isSchool = this.isSchool;
    creds.isTeacher = this.isTeacher;
    creds.manageUsers = this.manageUsers;

    this._sidenav.openSidenav(SidenavView.menu, creds);
  }

  logout() {
    this._authApi.logout();
  }

  async returnToHelpDesk() {

    //stop active hd session
    //call api to stop hd session and get current admin IUser
    let result = await this._adminApi.stopHelpDeskSession();
    if (result.HasError) {
      return;
    }
    //set jwt user to admin IUser to restore session
    let current_jwt = this._storage.getLocalStorage('jwt') as ILoginResponse;
    current_jwt.user = result.data;

    this._storage.setLocalStorage('jwt', current_jwt);

    //route to help desk page
    this._router.navigateByUrl('/u/admin/help-desk')
      .then(() => {
        window.location.reload();
      });
  }
}
