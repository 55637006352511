import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestApiService } from '@sharedServices/api/core/rest-api.service';
import { ErrorService } from '@sharedServices/error/error.service';
import { environment } from '@environments/environment';
import { ILookupApiResponse } from '../../models/api/lookup';
import { ApiResponse } from '@sharedModels/api-response';

@Injectable({
  providedIn: 'root'
})
export class LookupApiService extends RestApiService {
  private api: string;

  constructor(
    protected _http: HttpClient,
    protected _error: ErrorService
  ) {
    super(_http, _error);
    this.api = environment.api;
  }

  /**
   * @summary Gets a lookup from the api
   * @param lookup the URI to get the lookup for (e.g. "permissions")
   */
  async getLookup(lookup: string): Promise<ApiResponse<ILookupApiResponse[]>> {
    return await this.get(`${this.api}/lookup/${lookup}`);
  }
}
