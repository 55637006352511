<mat-form-field appearance="outline" class="w-100 {{errorClass}}" floatLabel="always">
  <mat-label>{{label}}</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date" #dateRangeStart [formControl]="controlStart" [required]="required"  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
    <input matEndDate formControlName="end" placeholder="End date" #dateRangeEnd [formControl]="controlEnd" [required]="required"  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>
