import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestApiService } from '@sharedServices/api/core/rest-api.service';
import { ErrorService } from '@sharedServices/error/error.service';
import { ApiResponse } from '@sharedModels/api-response';
import { IDashboardNews, IDashboardFollowUpCounts } from '@userModels/dashboard';
import { environment } from '@environments/environment';
import { ILandingNews } from '../../models/landing';
import { IUserGuide, IUserGuideFolder } from '../../models/user-guide';
import { ISystemNotification } from '../../models/system';
import { DistrictListingFilter, IDistrictAttachment, IHelpDeskUser, IManageDistrict, IManageSchool } from '../../models/admin';
import { IErrorResponse } from '../../models/error';
import { IIntegrationAccount, IIntegrationAggregate, IIntegrationErrorAggregate, IIntegrationLogEvent, IIntegrationSftpHistoryAggregate, IUpdateSftpFileFormat } from '../../models/integration';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService extends RestApiService {
  private adminApi: string;

  constructor(
    protected _http: HttpClient,
    protected _error: ErrorService
  ) {
    super(_http, _error);
    this.adminApi = `${environment.api}/admin`;
  }

  async getLandingNews(showActiveOnly: boolean = true): Promise<ApiResponse<ILandingNews[]>> {
    return await this.get(`${this.adminApi}/news?showActiveOnly=${showActiveOnly}`);
  }

  async updateLandingNewsSort(id: number, newSortOrder: number): Promise<ApiResponse<ILandingNews[]>> {
    return await this.put(`${this.adminApi}/news/${id}/sort/${newSortOrder}`, []);
  }

  async setupIntegrationAccount(payload: any): Promise<ApiResponse<IIntegrationAccount>> {
    return await this.post(`${this.adminApi}/setupIntegrationAccount`, payload);
  }

  async getIntegrationAccounts(): Promise<ApiResponse<any>> {
    return await this.get(`${this.adminApi}/integrations`);
  }

  async deleteUserGuideFolder(folderId: number): Promise<ApiResponse<IUserGuideFolder[]>> {
    return await this.delete(`${this.adminApi}/user-guide/folders/${folderId}`);
  }

  async saveUserGuideFolder(folderId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.post(`${this.adminApi}/user-guide/folders/${folderId}`, payload);
  }
  async getUserGuideFolders(): Promise<ApiResponse<IUserGuideFolder[]>> {
    return await this.get(`${this.adminApi}/user-guide/folders`);
  }

  async getIntegrationSftpHistory(apiUserId: number): Promise<ApiResponse<IIntegrationSftpHistoryAggregate>> {
    return await this.get(`${this.adminApi}/integrations/${apiUserId}/sftp`);
  }

  async deleteIntegrationAccount(apiUserId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.adminApi}/integrations/${apiUserId}`);
  }

  async getIntegrationLogs(apiUserId: number, sftpJobId: number): Promise<ApiResponse<IIntegrationAggregate>> {
    if (sftpJobId > 0) {
      return await this.get(`${this.adminApi}/integrations/${apiUserId}/logs?sftpJobId=${sftpJobId}`);
    } else {
      return await this.get(`${this.adminApi}/integrations/${apiUserId}/logs`);
    }
  }

  async getIntegrationErrors(apiUserId: number, sftpJobId: number): Promise<ApiResponse<IIntegrationErrorAggregate>> {
    return await this.get(`${this.adminApi}/integrations/${apiUserId}/errors/${sftpJobId}`);
  }

  public async getSftpJobZipArchive(apiUserId: number, sftpJobId: number): Promise<ApiResponse<Blob>> {
    return await this.get(`${this.adminApi}/integrations/${apiUserId}/sftp/${sftpJobId}/archive`, { responseType: 'blob' });
  }

  async saveIntegrationUsesSftp(apiUserId: number, value: boolean): Promise<ApiResponse<boolean>> {
    return await this.post(`${this.adminApi}/integrations/${apiUserId}/usesSftp/${value}`, {});
  }

  async saveIntegrationSftpFileTypes(payload: IUpdateSftpFileFormat): Promise<ApiResponse<any>> {
    return await this.put(`${this.adminApi}/integrations/${payload.apiUserId}/fileFormat`, payload);
  }

  async searchHelpDesk(payload: any): Promise<ApiResponse<IHelpDeskUser[]>> {
    return await this.post(`${this.adminApi}/helpdesk`, payload);
  }

  async getSystemNotification(): Promise<ApiResponse<ISystemNotification>> {
    return await this.get(`${this.adminApi}/systemNotification`);
  }

  async getLandingNewsById(newsId: number): Promise<ApiResponse<ILandingNews>> {
    return await this.get(`${this.adminApi}/news/${newsId}`);
  }

  async saveLandingNews(newsId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.post(`${this.adminApi}/news/${newsId}`, payload);
  }

  async saveSystemNotification(payload: any): Promise<ApiResponse<any>> {
    return await this.put(`${this.adminApi}/systemNotification`, payload);
  }

  async deleteLandingNews(newsId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.adminApi}/news/${newsId}`);
  }

  async startHelpDeskSession(hdUserId: number): Promise<ApiResponse<any>> {
    return await this.post(`${this.adminApi}/helpdesk/${hdUserId}`, {});
  }

  async checkActiveHelpDeskSession(): Promise<ApiResponse<any>> {
    return await this.get(`${this.adminApi}/helpdesk`);
  }

  async stopHelpDeskSession(): Promise<ApiResponse<any>> {
    return await this.delete(`${this.adminApi}/helpdesk`);
  }

  async getDistrictSearch(options: DistrictListingFilter): Promise<ApiResponse<IManageDistrict[]>> {
    return await this.post(`${this.adminApi}/districts${options.getQuery()}`, {});
  }

  async getDistrictSearchExport(options: DistrictListingFilter): Promise<ApiResponse<Blob>> {
    return await this.get(`${this.adminApi}/districts/export${options.getQuery()}`, { responseType: 'blob' });
  }

  async getDistrict(districtId: number): Promise<ApiResponse<IManageDistrict>> {
    return await this.get(`${this.adminApi}/districts/${districtId}`);
  }

  async saveDistrict(districtId: number, payload: IManageDistrict): Promise<ApiResponse<IErrorResponse>> {
    return await this.post(`${this.adminApi}/districts/${districtId}`, payload);
  }

  async saveDistrict504(districtId: number, value: boolean): Promise<ApiResponse<boolean>> {
    return await this.post(`${this.adminApi}/districts/${districtId}/is504/${value}`, {});
  }

  async saveDistrictApi(districtId: number, value: boolean): Promise<ApiResponse<boolean>> {
    return await this.post(`${this.adminApi}/districts/${districtId}/usesApi/${value}`, {});
  }

  async saveDistrictESign(districtId: number, value: boolean): Promise<ApiResponse<boolean>> {
    return await this.post(`${this.adminApi}/districts/${districtId}/usesESign/${value}`, {});
  }

  async saveDistrictCurrentContract(districtId: number, value: boolean): Promise<ApiResponse<boolean>> {
    return await this.post(`${this.adminApi}/districts/${districtId}/currentContract/${value}`, {});
  }

  async saveDistrictReadonly(districtId: number, value: boolean): Promise<ApiResponse<boolean>> {
    return await this.post(`${this.adminApi}/districts/${districtId}/readonly/${value}`, {});
  }

  async deleteDistrict(districtId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.adminApi}/districts/${districtId}`);
  }

  async uploadDistrictFormHeader(districtId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.post(`${this.adminApi}/formHeader/${districtId}`, payload);
  }

  async setupPassword(districtId: number): Promise<ApiResponse<any>> {
    return await this.get(`${this.adminApi}/setupPassword/${districtId}`);
  }

  async setupPasswordUsers(ids: any,): Promise<ApiResponse<any>> {
    return await this.get(`${this.adminApi}/setupPasswordUsers/${ids}`);
  }

  async getUserGuide(): Promise<ApiResponse<IUserGuide[]>> {
    return await this.get(`${this.adminApi}/user-guide`);
  }

  async getUserGuideById(ugId: number): Promise<ApiResponse<IUserGuide>> {
    return await this.get(`${this.adminApi}/user-guide/${ugId}`);
  }

  async deleteUserGuide(ugId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.adminApi}/user-guide/${ugId}`);
  }

  async saveUserGuide(ugId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.post(`${this.adminApi}/user-guide/${ugId}`, payload);
  }

  async getSchoolSearch(search: string): Promise<ApiResponse<IManageSchool[]>> {
    return await this.post(`${this.adminApi}/schools?search=${search}`, {});
  }

  async getSchool(schoolId: number): Promise<ApiResponse<IManageSchool>> {
    return await this.get(`${this.adminApi}/schools/${schoolId}`);
  }

  async saveSchool(schoolId: number, payload: IManageSchool): Promise<ApiResponse<IErrorResponse>> {
    return await this.post(`${this.adminApi}/schools/${schoolId}`, payload);
  }

  async deleteSchool(schoolId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.adminApi}/schools/${schoolId}`);
  }

  async restoreSchool(schoolId: number): Promise<ApiResponse<IErrorResponse>> {
    return await this.post(`${this.adminApi}/schools/restore/${schoolId}`,[])
  }

}
