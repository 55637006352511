<mat-form-field appearance="outline" class="w-100 {{errorClass}}"  floatLabel="always">
  <mat-label>{{label}}</mat-label>
  <input matInput [attr.type]="type && visible ? type : 'text'" [placeholder]="placeholder" [required]="required" [formControl]="control" autocomplete="off" (blur)="onBlur()" (focus)="onFocus()" [readonly]="readonly">
  <mat-hint align="end" *ngIf="focused && type === 'text'">{{control.value?.length || 0}} / {{maxLength}}</mat-hint>

  <!-- Prefixes/Suffixes with click event emitter -->
  <span matPrefix *ngIf="iconPrefix" (click)="clickPrefix()"><mat-icon>{{iconPrefix}}</mat-icon></span>
  <span matSuffix *ngIf="iconSuffix && type != 'password'" (click)="clickSuffix()"><mat-icon>{{iconSuffix}}</mat-icon></span>

  <!-- Specifically to handle password forms and the visibility toggle -->
  <span matSuffix *ngIf="type === 'password'" (click)="togglePasswordVisilibility()"><mat-icon>{{ !visible ? 'visibility' : 'visibility_off'}}</mat-icon></span>
</mat-form-field>
