
<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <h2 class="header">About BeyondSST</h2>
        <p>
          <strong>BeyondSST (SST)</strong> is managed and maintained by CodeStack, a department of the San Joaquin County Office of Education.
        </p>
        <p>Copyright 2020 SST. All Rights Reserved.</p>

        <div class="icon-line">
          <mat-icon>email</mat-icon><p class="link"><a href="mailto:info@beyondsst.org" target="_blank">Info&#64;BeyondSST.org</a></p>
        </div>
        <div class="icon-line">
          <mat-icon>phone</mat-icon> <span class="icon-description">(877) 779-0529</span>
        </div>

        <div class="logos">
          <a href="https://codestack.org/" target="_blank"><img class="logo" alt="CodeStack Logo" src="/assets/logo/codestack-logo.png"></a>
          <a href="https://www.sjcoe.org/" target="_blank"><img class="logo" alt="SJCOE Logo" src="/assets/logo/sjcoe-logo.png"></a>
        </div>
      </div>

      <div class="col-lg-4">
        <h2 class="header">Quick Links</h2>
        <p class="link"><a href="https://beyondsst.org/System_Requirements.pdf" target="_blank">System Requirements</a></p>
        <p class="link"><a href="/assets/SST_Terms_of_Use.pdf" target="_blank">Terms of Use Agreement</a></p>
      </div>

      <div class="col-lg-3">
        <h2 class="header">CodeStack Sites</h2>
        <p class="link pb-2"><a href="https://codestack.org/" target="_blank"><img class="logo" alt="CodeStack" src="/assets/logo/icon_codestack.png">CodeStack</a></p>
        <p class="link pb-2"><a href="https://seis.org/" target="_blank"><img class="logo" alt="SEIS" src="/assets/logo/icon_seis.png">SEIS</a></p>
        <p class="link pb-2"><a href="https://edjoin.org/" target="_blank"><img class="logo" alt="EDJOIN" src="/assets/logo/icon_edjoin.png">EDJOIN</a></p>
        <p class="link pb-2"><a href="https://mypromis.org/" target="_blank"><img class="logo" alt="PROMIS" src="/assets/logo/icon_promis.png">PROMIS</a></p>
        <p class="link pb-2"><a href="https://www.csconf.org/home/" target="_blank"><img class="logo" alt="CodeStack Concerence" src="/assets/logo/csclive.png">CodeStack Conference</a></p>
      </div>
    </div>
  </div>
</footer>
