export enum SidenavView {
  none,
  notifications,
  forms,
  student,
  user,
  affirm,
  menu,
  exit,
  referral
}

export interface ISidenavMessage {
  status: boolean;
  view: SidenavView;
  data?: any;
}
