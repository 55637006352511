import { Component, Input, NgZone, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { Validators, Editor, Toolbar, toHTML, ToolbarItem } from 'ngx-editor';


@Component({
  selector: 'sst-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit, OnDestroy{
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() required: boolean;
  @Input() linkMenu: boolean = false;
  @Input() control: UntypedFormControl;
  @Input() maxLength: number = 10000;
  focused: boolean;
  id: string;
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    //["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"]
  ];

  constructor(private _ngZone: NgZone) {
    // Todo: This is expirimentation
    // we need to pass formControl name on all form controls
    // that will have the optional ng-content detailed form label
    const randomNumber = Math.floor(Math.random() * Math.floor(100000)).toString();
    this.id = `textarea-control-${randomNumber}`;
  }

  ngOnInit(): void {
    this.editor = new Editor();

    if (this.linkMenu) {
      this.focused = true;
      var toolbarItem: ToolbarItem[];
      toolbarItem = [];
      toolbarItem.push("link");
      this.toolbar.push(toolbarItem);
    }
    //this.editor.valueChanges.subscribe(data => {
    //  console.log('Changed Values', data)
    //});

    //this.editor.update.subscribe(data => {
    //  console.log('updated Values', data)
    //});
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    // Snippet taken from angular material documentation.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  onBlur() {
    if (!this.linkMenu) {
      this.focused = false;
    } 
    //update value with convert to html?
    //console.log(this.control.value);
    this.control.setValue(toHTML(this.control.value));
    //console.log(this.control.value);
  }

  onFocus() {
    if (!this.linkMenu) {
      this.focused = true;
    }
  }

  setDisabled(value: boolean) {
    if (value) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }


}
