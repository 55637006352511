import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';


// Material imports
import { MatToolbarModule, MatToolbar } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';

import { SectionHeaderComponent } from './section-header/section-header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { InputComponent } from './forms/controls/input/input.component';
import { DatepickerComponent } from './forms/controls/datepicker/datepicker.component';
import { SelectComponent } from './forms/controls/select/select.component';
import { TextareaComponent } from './forms/controls/textarea/textarea.component';
import { CheckListComponent } from './forms/controls/check-list/check-list.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { RadioListComponent } from './forms/controls/radio-list/radio-list.component';
import { LabelComponent } from './forms/label/label.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgxEditorModule, Toolbar } from 'ngx-editor';
import { MatSidenavModule } from '@angular/material/sidenav';
import { WellComponent } from './well/well.component';
import { DaterangeComponent } from './forms/controls/daterange/daterange.component';
import { AutoScrollDirective } from './auto-scroll/auto-scroll.directive';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    SectionHeaderComponent,
    BreadcrumbsComponent,
    AutoScrollDirective,
    InputComponent,
    DatepickerComponent,
    SelectComponent,
    TextareaComponent,
    CheckListComponent,
    MessageBoxComponent,
    RadioListComponent,
    LabelComponent,
    FileUploadComponent,
    WellComponent,
    DaterangeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatMenuModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSidenavModule,
    NgxEditorModule

  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    SectionHeaderComponent,
    BreadcrumbsComponent,
    InputComponent,
    DatepickerComponent,
    DaterangeComponent,
    SelectComponent,
    TextareaComponent,
    CheckListComponent,
    MessageBoxComponent,
    AutoScrollDirective,
    RadioListComponent,
    FileUploadComponent,
    WellComponent
  ]
})
export class ComponentsModule { }
