import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestApiService } from '@sharedServices/api/core/rest-api.service';
import { ErrorService } from '@sharedServices/error/error.service';
import { environment } from '@environments/environment';
import { IPasswordUpdate, IUserListing, IUser, IUserTypesLookup, UserListingFilter, UserPermissionFilter } from '@sharedModels/api/user';
import { ApiResponse } from '@sharedModels/api-response';
import { ILookupApiResponse } from '../../../../models/api/lookup';
import { IUserGuide } from '../../models/user-guide';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends RestApiService {
  private api: string;

  constructor(
    protected _http: HttpClient,
    protected _error: ErrorService
  ) {
    super(_http, _error);
    this.api = environment.api;
  }

  // gets any user by id. will return unauthorized if permissions aren't granted.
  async getUser(userId: number): Promise<ApiResponse<IUser>> {
    return await this.get(`${this.api}/user/${userId}`);
  }

  // gets list of usre types available for role. will return unauthorized if permissions aren't granted.
  async getUserTypesByRole(roleId: number): Promise<ApiResponse<IUserTypesLookup[]>> {
    return await this.get(`${this.api}/lookup/userTypes?roleId=${roleId}`);
  }

  // Gets the user profile dto for the currently authenticated user
  async getUserProfile() {
    return await this.get(`${this.api}/user/profile`);
  }

  // updates the current logged in user profile
  async updateUserProfile(payload: { email: string, officePhone: string }): Promise<any> {
    return await this.put(`${this.api}/user/profile`, payload);
  }

  async changePassword(payload: IPasswordUpdate): Promise<ApiResponse<void>> {
    return await this.put(`${this.api}/user/changePassword`, payload);
  }

  async getNotifications() {
    return await this.get(`${this.api}/user/notifications`);
  }

  async deleteNotification(notificationId: number) {
    return await this.delete(`${this.api}/user/notification/${notificationId}`);
  }

  async markNotificationAsRead(notificationId: number) {
    return await this.put(`${this.api}/user/notification/${notificationId}/read`, null);
  }

  async updateNotifications(action: string, notificationIds: number[]) {
    return await this.post(`${this.api}/user/notifications/action`, {action: action, notificationIds: notificationIds});
  }

  // User Management
  // Requires Permissions

  async getUserPermissions(userId: number): Promise<ApiResponse<any>> {
    return await this.get(`${this.api}/user/perms/${userId}`);
  }

  async updateUserPermissions(userId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.put(`${this.api}/user/perms/${userId}`, payload);
  }

  async createUser(payload: any): Promise<ApiResponse<any>> {
    return await this.post(`${this.api}/user`, payload);
  }

  async updateUser(userId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.put(`${this.api}/user/${userId}`, payload);
  }

  async updateUserNotificationSettings(userId: number, payload: any): Promise<ApiResponse<any>> {
    return await this.put(`${this.api}/user/${userId}/notification-settings`, payload);
  }

  async deleteUser(userId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.api}/user/${userId}`);
  }

  async deleteUserPerm(userId: number): Promise<ApiResponse<any>> {
    return await this.delete(`${this.api}/user/${userId}/perm`);
  }

  async restoreUser(userId: number): Promise<ApiResponse<any>> {
    return await this.post(`${this.api}/user/${userId}/restore`, []);
  }

  //async getUsers(): Promise<ApiResponse<IUserListing>> {
  //  return await this.get(`${this.api}/user/listing`);
  //}

  async getUsers(options: UserListingFilter): Promise<ApiResponse<IUserListing>> {
    return await this.get(`${this.api}/user/listing${options.getQuery()}`);
  }

  async getUsersExport(options: UserListingFilter): Promise<ApiResponse<Blob>> {
    return await this.get(`${this.api}/user/listing-export${options.getQuery()}`, { responseType: 'blob' });
  }

  async getUserProvidersBySchool(schoolId: number): Promise<ApiResponse<IUser[]>> {
    return await this.get(`${this.api}/user/providers/${schoolId}`);
  }

  async getUserGuideById(ugId: number): Promise<ApiResponse<IUserGuide>> {
    return await this.get(`${this.api}/userGuide/${ugId}`);
  }

  async downloadUserGuide(ugId: number): Promise<ApiResponse<Blob>> {
    return await this.get(`${this.api}/userGuide/${ugId}/download`, { responseType: 'blob' });
  }

  async setUsersPermissions(permissionId: number, ids: any, flagId: number): Promise<ApiResponse<any>> {
    const bulkUpdateUser = {
      permissionId: permissionId,
      ids: ids,
      flagId: flagId
    }; 
    return await this.put(`${this.api}/user/bulkUserPermission`, bulkUpdateUser);
  }
  async setupPasswordUsers(ids: any,): Promise<ApiResponse<any>> {
    return await this.get(`${this.api}/user/setupPasswordUsers/${ids}`);
  }

  async getPermissionUsers(options: UserPermissionFilter): Promise<ApiResponse<IUserListing>> {
    return await this.get(`${this.api}/user/permissionUsers${options.getFilteredQuery()}`);
    }

  async getPermissionUsersExport(options: UserPermissionFilter): Promise<ApiResponse<Blob>> {
    return await this.get(`${this.api}/user/permissionUsers-export${options.getFilteredQuery()}`, { responseType: 'blob' });
  }
}
