

<ng-container>
  <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="'true'" class="w-100">
    <mat-label>{{label}}</mat-label>
    <textarea matInput #messageInput placeholder="Enter message" [formControl]="control"
              style="display: none;"></textarea>

    <ngx-editor [editor]="editor"
                [formControl]="control"
                [required]="required"
                (focusout)="onBlur()"
                (click)="onFocus()"
                [placeholder]="placeholder"></ngx-editor>
    <ngx-editor-menu *ngIf="focused" [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
    <!--<mat-hint align="end" *ngIf="focused && control.value?.length <= maxLength">{{control.value?.length || 0}} / {{maxLength}}</mat-hint>-->
    <mat-hint align="end" *ngIf="focused && control.value?.length > maxLength" (click)="setDisabled(true)" style="color: red;"><h5>Maximum length exceeded.  More than {{maxLength}} characters will not be saved.</h5></mat-hint>
    <br />
  </mat-form-field>
  <div>&nbsp;</div>
</ng-container>
