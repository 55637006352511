import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sstAutoScroll]'
})
export class AutoScrollDirective {

  @Input('sstAutoScroll') targetElementId: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    event.preventDefault();

    const targetElement = document.getElementById(this.targetElementId);
    if (targetElement) {
      this.scrollToElement(targetElement);
    }
  }

  private scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
