import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@sharedModels/api-response';
import { ErrorService } from '@sharedServices/error/error.service';
import { AuthApiService } from '../auth-api.service';
import { StorageService } from '../../storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  private _storageService = inject(StorageService);
  private _route = inject(Router);
  constructor(protected _http: HttpClient, protected _error: ErrorService) { }

  /**
   * @summary Generic GET call wrapper
   * @param endpoint The full URL endpoint to hit
   * @param options  Optional HTTP options to send with the request.
   */
  protected async get(endpoint: string, options?: any): Promise<ApiResponse<any>> {
    return await this.call(() => this._http.get(endpoint, options));
  }

  /**
   * @summary Generic POST call wrapper
   * @param endpoint The full URL endpoint to hit
   * @param body The payload to send in the body of the request
   * @param options  Optional HTTP options to send with the request.
   */
  protected async post(endpoint: string, body: any, options?: any): Promise<ApiResponse<any>> {
    return await this.call(() => this._http.post(endpoint, body, options));
  }

  /**
   * @summary Generic PUT call wrapper
   * @param endpoint The full URL endpoint to hit
   * @param body The payload to send in the body of the request
   * @param options  Optional HTTP options to send with the request.
   */
  protected async put(endpoint: string, body: any, options?: any): Promise<ApiResponse<any>> {
    return await this.call(() => this._http.put(endpoint, body, options));
  }

  /**
   * @summary Generic PATCH call wrapper
   * @param endpoint The full URL endpoint to hit
   * @param body The payload to send in the body of the request
   * @param options  Optional HTTP options to send with the request.
   */
  protected async patch(endpoint: string, body: any, options?: any): Promise<ApiResponse<any>> {
    return await this.call(() => this._http.patch(endpoint, body, options));
  }

  /**
   * @summary Generic DELETE call wrapper
   * @param endpoint The full URL endpoint to hit
   * @param options  Optional HTTP options to send with the request.
   */
  protected async delete(endpoint: string, options?: any): Promise<ApiResponse<any>> {
    return await this.call(() => this._http.delete(endpoint, options));
  }

  private async call(method: Function) {
    try {
      const response = await method().toPromise();
      return new ApiResponse<any>({ data: response });
    } catch (error) {
      this._error.logHttpError(error);
      if (error.status === 401) { //if unauthorized, log user out and direct to login.
        console.log('unauthorized, logging out');
        this._storageService.removeLocalStorage('jwt');
        this._route.navigateByUrl('/login');
      }
      return new ApiResponse<any>({ error });
    }
  }
}
