export enum UserRoles {
  District = 1,
  School = 2,
  Teacher = 3,
  Admin = 4,
  Selpa = 5
}

export enum UserPermissions {
  AddStudent = 1,
  AffirmSst = 2,
  EditDeleteAttachments = 4,
  EditStudentForms = 5,
  EditAffirm504 = 6,
  MarkFormsCompleteOrRequiresCorrection = 7,
  ReferStudents = 8,
  Announcements = 9, // Todo: I'm not sure what permission this is based on
  DeleteStudent = 11,
  EditStudent = 12,
  BulkUpdateTeachersOrProviders = 13,
  ChangeStatus = 14, // Todo: I'm not sure what permission this is based on
  EditReferredStudents = 15,
  ManageUsers = 17,
  TransferredStudentListing = 19, // Todo: I'm not sure what permission this is based on
  TransferringOutOrIntoDistrict = 20, // Todo: I'm not sure what permission this is based on
  UnblockForm = 21,
  EditExitedStudents = 22,
  Migrate_Accommodation_Data = 23,
  Bulk_Update_Permission = 24, // permission
  Manage_Document_Library = 25,
  TOMS_Integration_Admin = 26,
  Edit_Library_Items = 27,
  DeleteUser = 28

}
