import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestApiService } from '@sharedServices/api/core/rest-api.service';
import { ErrorService } from '@sharedServices/error/error.service';
import { ApiResponse } from '../../models/api-response';
import { ILoginResponse, ILoginPayload, ILoginUser } from '@sharedModels/api/login';
import { environment } from '@environments/environment';
import { IContact } from '../../models/api/contact';
import { IHelpCenter } from '../../models/api/helpcenter';
import { StorageService } from '../storage.service';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends RestApiService {
  private api: string;

  constructor(
    protected _http: HttpClient,
    protected _error: ErrorService,
    private _storage: StorageService,
    private _appService: AppService,
    private _router: Router,
  ) {
    super(_http, _error);
    this.api = environment.api;
  }

  async login(payload: ILoginPayload): Promise<ApiResponse<ILoginResponse>> {
    return await this.post(`${this.api}/Auth/login`, payload);
  }

  logout() {
    this._appService.setUserLoggedIn(false);
    this._storage.removeLocalStorage('jwt');
    this._router.navigateByUrl('/login');
  }

  async contact(payload: IContact): Promise<ApiResponse<ApiResponse<any>>> {
    return await this.post(`${this.api}/Auth/contact`, payload);
  }

  async helpCenter(payload: IHelpCenter): Promise<ApiResponse<ApiResponse<any>>> {
    return await this.post(`${this.api}/Auth/helpcenter`, payload);
  }

  async passwordResetUsername(payload: IContact): Promise<ApiResponse<ApiResponse<any>>> {
    return await this.post(`${this.api}/Auth/passwordResetUsername`, payload);
  }

  async passwordReset(payload: IContact): Promise<ApiResponse<ApiResponse<any>>> {
    return await this.post(`${this.api}/Auth/passwordReset`, payload);
  }

  async passwordChange(payload: IContact): Promise<ApiResponse<ApiResponse<any>>> {
    return await this.post(`${this.api}/Auth/passwordChange`, payload);
  }

  async termsOfUse(userId: number): Promise<ApiResponse<ApiResponse<ILoginResponse>>> {
    return await this.post(`${this.api}/Auth/termsOfUse/${userId}`,userId);
  }

}
