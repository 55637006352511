import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'sst-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.scss']
})
export class RadioListComponent {
  @Input() label: string;
  @Input() valueKey: string;
  @Input() optionKey: string;
  @Input() options: any[] = [];
  @Input() required: boolean;
  @Input() control: UntypedFormGroup;
}
