import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'sst-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss']
})
export class DaterangeComponent implements OnInit {
  errorClass: string = '';
  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;
  @Input() controlStart: UntypedFormControl;
  @Input() controlEnd: UntypedFormControl;


  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

  constructor() {

  }

  ngOnInit(): void {
    if (this.controlStart.invalid || this.controlEnd.invalid) {
      this.errorClass = 'mat-form-field-invalid';
    }
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    var startDate = new Date(dateRangeStart.value);
    var endDate = new Date(dateRangeEnd.value);
    
    this.range.controls.start.setValue(startDate.toJSON());
    this.range.controls.end.setValue(endDate.toJSON());

    this.controlEnd.setValue(endDate.toJSON());
    this.controlStart.setValue(startDate.toJSON());

    this.onSelectionChange.emit(`${dateRangeStart.value},${dateRangeEnd.value}`);
  }

  onChange(event: any) {
    this.onSelectionChange.emit(event);
  }

}
