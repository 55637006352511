<div class="row">
  <div class="col-12 mb-1">
    <mat-label>{{label}}</mat-label>
  </div>
  <div class="col-5" *ngFor="let option of options">
    <mat-checkbox
      color="primary"
      (change)="onChange($event)"
      [value]="option[valueKey]"
      [checked]="option.isChecked">
      {{option[optionKey]}}</mat-checkbox>
  </div>
</div>
